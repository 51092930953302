import {Controller} from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
    static values = {draggable: String}

    connect() {
        this._initSortable()
    }

    _initSortable() {
        return Sortable.create(this.element, {
            animation: 250,
            draggable: this.draggableValue,
            onEnd: this._onEnd.bind(this),
            fallbackOnBody: true,
            forceFallback: true
        })
    }

    _onEnd(_e) {
        this.element.querySelectorAll(this.draggableValue).forEach((draggable, key) => {
            const order = (key + 1).toString()
            draggable.querySelector('input[name$="[order]"]').value = order
            draggable.querySelector('.document-preview--counter-badge').innerText = order
        })
    }
}
