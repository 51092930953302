import {Controller} from '@hotwired/stimulus'
import Modal from 'bootstrap/js/dist/modal'

export default class extends Controller {
    connect() {
        this.modal = new Modal(this.element)
    }

    open(event) {
        if (event.target === null || event.target.id !== 'modal') {
            // Ignore all fired events that dies not come directly from the "modal" target.
            event.stopPropagation()
            return
        }

        if (!this.modal._isShown) this.modal.show()
    }

    close(event) {
        if (event.detail.success && !event.target.hasAttribute('data-do-not-close-modal-on-submit')) {
            this.modal.hide()
        }
    }

    closeNow(_event) {
        this.modal.hide()
    }

    shown(event) {
        event.target.querySelector('[autofocus]')?.focus()
    }
}
