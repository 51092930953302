import NestedForm from 'stimulus-rails-nested-form'

/*
  on remove simply removes the complete wrapper instead of setting _destroy input value
 */
export default class extends NestedForm {
    connect() {
        super.connect()
    }

    remove(e) {
        const wrapper = e.target.closest(this.wrapperSelectorValue)
        wrapper.remove()
    }
}