import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import de_DE from '@uppy/locales/src/de_DE'
import en_US from '@uppy/locales/src/en_US'

import DragDrop from '@uppy/drag-drop'
import StatusBar from '@uppy/status-bar'

export const setupUppy = ({
                              maxNumberOfFiles,
                              minNumberOfFiles,
                              fileInputTarget,
                              dragdropTarget,
                              progressBarTarget,
                              accept,
                              acceptHeader,
                              uploadEndpoint
                          }) => {

    const uppy = new Uppy({
        locale: document.documentElement.lang === 'en' ? en_US : de_DE,
        autoProceed: true,
        allowMultipleUploadBatches: true,
        restrictions: {
            maxNumberOfFiles: maxNumberOfFiles || 10,
            minNumberOfFiles: minNumberOfFiles || 1,
            maxFileSize: 419430400, // 50mb
            allowedFileTypes: accept.split(',')
        }
    })

    uppy.use(XHRUpload, {
        method: 'POST',
        endpoint: uploadEndpoint,
        formData: true,
        headers: {
            'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
            'Accept': acceptHeader || 'application/json'
        },
        fieldName: 'file',
        getResponseData: (responseText, response) => {
            if (response.getResponseHeader('content-type').startsWith('application/json')) {
                return JSON.parse(responseText)
            } else {
                return responseText
            }
        }
    })

    if (fileInputTarget) {
        fileInputTarget.addEventListener('change', event => {
            const files = Array.from(event.target.files)

            files.forEach(file => {
                try {
                    uppy.addFile({
                        source: 'file input',
                        name: file.name,
                        type: file.type,
                        data: file
                    })
                } catch (err) {
                    console.error(err)
                }
            })
        })
    }

    uppy.on('file-removed', () => {
        if (fileInputTarget) fileInputTarget.value = null
    })

    uppy.on('complete', () => {
        if (fileInputTarget) fileInputTarget.value = null
    })

    if (dragdropTarget) {
        uppy.use(DragDrop, {
            target: dragdropTarget
        })
    }

    if (progressBarTarget) {
        uppy.use(StatusBar, {
            target: progressBarTarget,
            hideAfterFinish: !!fileInputTarget,
            hideCancelButton: true,
            hidePauseResumeButton: true,
            hideRetryButton: true
        })
    }

    return uppy
}
