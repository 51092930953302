import {Controller} from '@hotwired/stimulus'
import * as pdfjs from 'pdfjs-dist'
import {buildPdfjsToolbar} from '../components/pdfjs/toolbar_helper'
import {PdfViewer} from '../components/pdfjs/viewer'
import {DraggableSignature} from "../components/pdfjs/draggable_signature";
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import {DraggableText} from "../components/pdfjs/draggable_text";

export default class extends Controller {
    static targets = ['sidebarLeft', 'main', 'toolbar', 'submit', 'signature', 'toolbar']
    static values = {pdfUrl: String, disableSignatures: Boolean}

    connect() {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
        const loader = pdfjs.getDocument(this.pdfUrlValue)
        this._setupPdf(loader)
        this._setupSubmit()
        this.activePage = 1
        this.viewScale = 1
        this.initialized = false
    }

    _setupSubmit() {
        this.submitTarget.addEventListener('click', () => {
            const form = this.submitTarget.form

            this.pdfViewer.draggables.forEach(draggable => {
                const addInput = (name, value) => {
                    const input = document.createElement('input')
                    input.setAttribute('name', `manipulation_step[manipulation_options][sign][${draggable.id}][attributes][${name}]`)
                    input.value = value
                    input.type = 'hidden'
                    form.appendChild(input)
                }

                const formData = draggable.formData()
                Object.keys(formData).forEach(key => (addInput(key, formData[key])))

                const input = document.createElement('input')
                input.type = 'hidden'
                if (formData.file_blob_uuid) {
                    input.value = formData.file_blob_uuid
                    input.setAttribute('name', `manipulation_step[manipulation_options][sign][${draggable.id}][file_blob_uuid]`)
                } else if (formData.text) {
                    input.value = formData.text
                    input.setAttribute('name', `manipulation_step[manipulation_options][sign][${draggable.id}][text]`)
                }
                form.appendChild(input)
            })

            form.submit()
        })
    }

    addSignatureToActivePage(source) {
        const wrapper = this.pdfViewer.pages[this.activePage].pageDom.canvasWrapper

        const data = JSON.parse(source.getAttribute('data-file-blob-json'))
        const draggable = new DraggableSignature(data, this.viewScale)

        this._addDraggable(wrapper, draggable)
    }

    addTextToActivePage() {
        const wrapper = this.pdfViewer.pages[this.activePage].pageDom.canvasWrapper

        const draggable = new DraggableText(this.viewScale)
        this._addDraggable(wrapper, draggable)
    }

    _addDraggable(wrapper, draggable) {
        wrapper.appendChild(draggable.wrapper)

        draggable.removeBtn.addEventListener('click', () => {
            const index = this.pdfViewer.draggables.indexOf(draggable)
            this.pdfViewer.draggables.splice(index, 1)
            draggable.wrapper.remove()
        })

        draggable.wrapper.style.left = `${(draggable.wrapper.parentElement.clientWidth - draggable.wrapper.clientWidth) / 2}px`
        draggable.wrapper.style.top = `${(draggable.wrapper.parentElement.clientHeight - draggable.wrapper.clientHeight) / 2}px`

        this.pdfViewer.draggables.push(draggable)

        this.submitTarget.removeAttribute('disabled')
    }

    signatureTargetConnected(signature) {
        signature.addEventListener('click', () => {
            this.addSignatureToActivePage(signature)
        })
        if (this.initialized) this.addSignatureToActivePage(signature)
    }

    increaseScale() {
        if (this.viewScale < 3) {
            this.viewScale = parseFloat((this.viewScale + 0.4).toFixed(2))
            this.pdfViewer.changeScaleAndRender(this.viewScale)
        }
    }

    decreaseScale() {
        if (this.viewScale > 0.4) {
            this.viewScale = parseFloat((this.viewScale - 0.4).toFixed(2))
            this.pdfViewer.changeScaleAndRender(this.viewScale)
        }
    }

    async _setupPdf(loader) {
        const pdfDocumentProxy = await loader.promise
        const firstPageProxy = await pdfDocumentProxy.getPage(1)
        const [_x, _y, _width, height] = firstPageProxy.view
        // this.viewScale = (this.element.clientHeight - 86) / height

        this.pdfViewer = new PdfViewer(pdfDocumentProxy, {
                mainParent: this.mainTarget,
                sidebarParent: this.sidebarLeftTarget,
                height: this.element.clientHeight,
                scale: this.viewScale
            },
            {
                setActivePage: this._setActivePage.bind(this),
                scrollPage: pageNumber => {
                    this.activePage = pageNumber
                    this.pageSelect.value = pageNumber.toString()
                }
            })
        this.totalPages = pdfDocumentProxy.numPages
        this.pdfViewer.setupPages()
        buildPdfjsToolbar(this, this.disableSignaturesValue)
        this.initialized = true
    }

    _setActivePage(pageNumber) {
        if (pageNumber <= 0 || pageNumber > this.totalPages || pageNumber === this.activePage) return

        const pageWrapper = this.pdfViewer.pages[pageNumber].pageDom.wrapper
        this.mainTarget.scrollTo(0, pageWrapper.offsetTop - 258)
        this.activePage = pageNumber
        this.pageSelect.value = pageNumber.toString()
    }
}
