import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        this.offsetTop = this.element.offsetTop
        this._setHeight()

        window.addEventListener('resize', this._setHeight.bind(this))
    }

    _setHeight() {
        const height = window.innerHeight - this.offsetTop
        this.element.style.height = `${height}px`
    }
}
