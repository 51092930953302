import {Controller} from '@hotwired/stimulus'
import {setupUppy} from '../components/uppy/uploader'

export default class extends Controller {
    static targets = ['form', 'progressBar', 'dragdrop', 'progressBar']
    static values = {
        accept: String,
        maxNumberOfFiles: Number,
        uploadEndpoint: String
    }

    connect() {
        const uppy = setupUppy({
            maxNumberOfFiles: this.maxNumberOfFilesValue,
            accept: this.acceptValue,
            dragdropTarget: this.hasDragdropTarget ? this.dragdropTarget : null,
            progressBarTarget: this.hasProgressBarTarget ? this.progressBarTarget : null,
            formTarget: this.formTarget,
            acceptHeader: 'text/vnd.turbo-stream.html',
            uploadEndpoint: this.uploadEndpointValue
        })

        uppy.on('upload-success', (file, response) => {
            Turbo.renderStreamMessage(response.body)
            this.dispatch('uploadDone')
        })
    }
}
