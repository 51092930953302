export class PdfViewer {
    constructor(pdfDocumentProxy, {mainParent, sidebarParent, height, scale}, on) {
        this.pdfDocumentProxy = pdfDocumentProxy
        this.totalPages = pdfDocumentProxy.numPages
        this.mainParent = mainParent
        this.sidebarParent = sidebarParent
        this.height = height
        this.on = on
        this.pages = {}
        this.draggables = []
        this.scale = scale

        this.pageObserver = new IntersectionObserver(changes => {
            changes.forEach(change => {
                if (change.isIntersecting) {
                    const pageNumber = parseInt(change.target.getAttribute('data-page'))

                    const page = this.pages[pageNumber]
                    if (!page.rendered) page.render()

                    if (change.intersectionRatio > 0.7 && this.on.scrollPage) {
                        this.on.scrollPage(pageNumber)
                    }
                }
            })
        }, {root: mainParent, threshold: [0, 0.7]})

        this.previewObserver = new IntersectionObserver(changes => {
            changes.forEach(change => {
                if (change.isIntersecting) {
                    const pageNumber = parseInt(change.target.getAttribute('data-page'))

                    const page = this.pages[pageNumber]
                    page.renderPreview()
                    this.previewObserver.unobserve(page.previewDom.wrapper)
                }
            })
        })
    }

    async setupPages() {
        for (let pageNumber = 1; pageNumber <= this.totalPages; pageNumber++) {
            const pageProxy = await this.pdfDocumentProxy.getPage(pageNumber)
            const pdfjsPage = new PdfjsPage(pageProxy, this.scale)
            pdfjsPage.setupPage()
            pdfjsPage.setupPreview()
            this.pages[pageNumber] = pdfjsPage
            this.mainParent.appendChild(pdfjsPage.pageDom.wrapper)
            this.sidebarParent.appendChild(pdfjsPage.previewDom.wrapper)

            this.pageObserver.observe(pdfjsPage.pageDom.canvasWrapper)
            this.previewObserver.observe(pdfjsPage.previewDom.wrapper)

            if (this.on.setActivePage) {
                pdfjsPage.previewDom.wrapper.addEventListener('click', () => (this.on.setActivePage(pageNumber)))
            }
        }
    }

    changeScaleAndRender(scale) {
        for (const pageNumber in this.pages) {
            if (elementInViewport(this.pages[pageNumber].pageDom.wrapper)) {
                this.pages[pageNumber].changeScaleAndRender(scale)
            } else {
                this.pages[pageNumber].rendered = false
                this.pages[pageNumber].scale = scale
            }
        }

        this.draggables.forEach(draggable => {
            draggable.rescale(this.scale, scale)
        })

        this.scale = scale
    }
}

export class PdfjsPage {
    constructor(pageProxy, scale = 1) {
        this.pageProxy = pageProxy
        this.pageNumber = this.pageProxy.pageNumber
        this.scale = scale
        this.previewScale = 0.2
        this.rendered = false
        this.pageDom = {}
        this.previewDom = {}

        const viewport = pageProxy.getViewport({scale})
        this.dimensions = {width: viewport.width, height: viewport.height}
        this.previewViewport = pageProxy.getViewport({scale: this.previewScale})
        this.previewDimensions = {width: this.previewViewport.width, height: this.previewViewport.height}
    }

    setupPage() {
        this.pageDom.wrapper = document.createElement('div')
        this.pageDom.wrapper.style.padding = '8px'
        this.pageDom.wrapper.style.margin = '8px'

        this.pageDom.canvasWrapper = document.createElement('div')
        this.pageDom.canvasWrapper.classList.add('position-relative')
        this.pageDom.canvasWrapper.classList.add('d-inline-block')
        this.pageDom.canvasWrapper.setAttribute('data-page', this.pageNumber.toString())

        this.pageDom.canvas = document.createElement('canvas')
        this.pageDom.ctx = this.pageDom.canvas.getContext('2d')
        this.pageDom.canvas.style.boxShadow = '0 1px 10px 1px rgba(0,0,0,0.5)'
        this.pageDom.canvas.style.display = 'block'

        this._setCanvasDimensions(this.dimensions)
        this.pageDom.canvasWrapper.setAttribute('data-pdf-width', this.dimensions.width.toString())
        this.pageDom.canvasWrapper.setAttribute('data-pdf-height', this.dimensions.height.toString())

        this.pageDom.canvasWrapper.appendChild(this.pageDom.canvas)
        this.pageDom.wrapper.appendChild(this.pageDom.canvasWrapper)
    }

    setupPreview() {
        this.previewDom.wrapper = document.createElement('div')
        this.previewDom.wrapper.style.padding = '8px'
        this.previewDom.wrapper.style.margin = '8px'
        this.previewDom.wrapper.classList.add('pdfjs--preview-page-wrapper')
        this.previewDom.wrapper.setAttribute('data-page', this.pageNumber.toString())

        this.previewDom.canvas = document.createElement('canvas')
        this.previewDom.canvas.style.boxShadow = '0 1px 10px 1px rgba(0,0,0,0.5)'
        this.previewDom.canvas.style.display = 'block'
        this.previewDom.canvas.width = Math.floor(this.previewDimensions.width)
        this.previewDom.canvas.height = Math.floor(this.previewDimensions.height)
        this.previewDom.canvas.style.width = Math.floor(this.previewDimensions.width) + 'px'
        this.previewDom.canvas.style.height = Math.floor(this.previewDimensions.height) + 'px'
        this.previewDom.canvas.style.margin = 'auto'
        this.previewDom.ctx = this.previewDom.canvas.getContext('2d')

        const info = document.createElement('div')
        info.innerText = this.pageNumber
        info.classList.add('text-center')
        info.classList.add('py-1')

        this.previewDom.wrapper.appendChild(this.previewDom.canvas)
        this.previewDom.wrapper.appendChild(info)
    }

    renderPreview() {
        this.pageProxy.render({canvasContext: this.previewDom.ctx, viewport: this.previewViewport})
    }

    render() {
        const viewport = this.pageProxy.getViewport({scale: this.scale})
        this._setCanvasDimensions({width: viewport.width, height: viewport.height})
        this.pageProxy.render({canvasContext: this.pageDom.ctx, viewport: viewport})
        this.rendered = true
    }

    changeScaleAndRender(scale) {
        this.scale = scale
        if (this.rendered) this.render()
    }

    _setCanvasDimensions({width, height}) {
        this.pageDom.canvas.width = Math.floor(width)
        this.pageDom.canvas.height = Math.floor(height)
        this.pageDom.canvas.style.width = Math.floor(width) + 'px'
        this.pageDom.canvas.style.height = Math.floor(height) + 'px'

        this.pageDom.wrapper.style.padding = `${8 * this.scale}px`
        this.pageDom.wrapper.style.margin = `${8 * this.scale}px`
    }
}

function elementInViewport(element) {
    const bounding = element.getBoundingClientRect()

    return (bounding.top >= -element.offsetHeight
        && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + element.offsetHeight)
}