export const makeResizable = element => {
    const handleWrapper = document.createElement('div')
    handleWrapper.classList.add('resizable--handle-wrapper')

    element.appendChild(handleWrapper)

    const resizing = {
        topLeft: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false
    }

    const setupResizableHandle = ({direction, moveHandler}) => {
        const handle = document.createElement('div')
        handle.classList.add(`resizable--handle`)
        handle.classList.add(`resizable--handle-${direction}`)

        handle.addEventListener('mousedown', e => {
            resizing[direction] = true
            e.stopPropagation()
        })

        window.addEventListener('mousemove', e => {
            e.stopPropagation()
            e.preventDefault()
            if (!resizing[direction]) return

            moveHandler(element, e)
        })

        window.addEventListener('mouseup', () => {
            resizing[direction] = false
        })

        return handle
    }

    const topLeft = setupResizableHandle({direction: 'topLeft', moveHandler: topLeftMoveHandler})
    handleWrapper.appendChild(topLeft)

    const topRight = setupResizableHandle({direction: 'topRight', moveHandler: topRightMoveHandler})
    handleWrapper.appendChild(topRight)

    const bottomLeft = setupResizableHandle({direction: 'bottomLeft', moveHandler: bottomLeftMoveHandler})
    handleWrapper.appendChild(bottomLeft)

    const bottomRight = setupResizableHandle({direction: 'bottomRight', moveHandler: bottomRightMoveHandler})
    handleWrapper.appendChild(bottomRight)
}

const topLeftMoveHandler = (target, e) => {
    const newWidth = target.clientWidth - e.movementX
    if (newWidth > 50) {
        const newLeft = target.offsetLeft + e.movementX
        if (newLeft >= 0) {
            target.style.width = `${newWidth}px`
            target.style.left = `${newLeft}px`
        }
    }

    const newHeight = target.clientHeight - e.movementY
    if (newHeight > 20) {
        const newTop = target.offsetTop + e.movementY
        if (newTop >= 0) {
            target.style.height = `${newHeight}px`
            target.style.top = `${newTop}px`
        }
    }
}

const topRightMoveHandler = (target, e) => {
    const newWidth = target.clientWidth + e.movementX
    if (newWidth > 50 && newWidth + target.offsetLeft < target.parentElement.clientWidth) {
        target.style.width = `${newWidth}px`
    }

    const newHeight = target.clientHeight - e.movementY
    if (newHeight > 20) {
        const newTop = target.offsetTop + e.movementY
        if (newTop >= 0) {
            target.style.height = `${newHeight}px`
            target.style.top = `${newTop}px`
        }
    }
}

const bottomLeftMoveHandler = (target, e) => {
    const newWidth = target.clientWidth - e.movementX
    if (newWidth > 50) {
        const newLeft = target.offsetLeft + e.movementX
        if (newLeft >= 0) {
            target.style.width = `${newWidth}px`
            target.style.left = `${newLeft}px`
        }
    }

    const newHeight = target.clientHeight + e.movementY
    if (newHeight > 20 && newHeight + target.offsetTop < target.parentElement.clientHeight) {
        target.style.height = `${newHeight}px`
    }
}

const bottomRightMoveHandler = (target, e) => {
    const newWidth = target.clientWidth + e.movementX
    if (newWidth > 50 && newWidth + target.offsetLeft < target.parentElement.clientWidth) {
        target.style.width = `${newWidth}px`
    }

    const newHeight = target.clientHeight + e.movementY
    if (newHeight > 20 && newHeight + target.offsetTop < target.parentElement.clientHeight) {
        target.style.height = `${newHeight}px`
    }
}