import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['rotateLeft', 'rotateRight', 'preview', 'input', 'info']

    connect() {
        this.rotate = 0

        this.rotateLeftTarget.addEventListener('click', () => {
            this._rotateBy(-90)
            this._update()
        })

        this.rotateRightTarget.addEventListener('click', () => {
            this._rotateBy(90)
            this._update()
        })
    }

    _update() {
        this._updateInfo()
        this._rotatePreview()
        this._setInputDeg()
    }

    _rotateBy(deg) {
        this.rotate = (this.rotate + deg + 360) % 360
    }

    _updateInfo() {
        if (this.rotate === 0) {
            this.infoTarget.innerText = ''
        } else {
            this.infoTarget.innerText = `${this.rotate}°`
        }
    }

    _rotatePreview() {
        this.previewTarget.style.transform = `rotate(${this.rotate}deg)`

        const parentWidth = this.previewTarget.parentElement.clientWidth

        if (this.rotate % 180 !== 0) {
            this.previewTarget.style.height = `${parentWidth}px`
        } else {
            this.previewTarget.style.height = null
        }
    }

    _setInputDeg() {
        this.inputTarget.value = this.rotate
    }
}
