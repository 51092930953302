import {Controller} from '@hotwired/stimulus'
import PagePreview from '../components/pdfjs/page_preview'

export default class extends Controller {
    static targets = ['preview']
    static values = {
        previewUrl: String,
        stepFileInputId: Number,
        pageText: {type: String, default: 'Page'}
    }

    async connect() {
        const options = {
            pdfUrl: this.previewUrlValue,
            previewTarget: this.previewTarget,
            buildPage: this._buildPage.bind(this)
        }
        const preview = new PagePreview(options)
        preview.setupPdfJs()
        preview.setupObserver()
        await preview.loadPdf()
        await preview.buildDom()

        this.pages = {}
    }

    _buildPage(pageNumber) {
        const wrapper = document.createElement('div')
        wrapper.classList.add('cut-pdf-page')
        wrapper.classList.add('d-flex')
        wrapper.classList.add('flex-column')
        wrapper.classList.add('justify-content-between')
        wrapper.setAttribute('data-page', pageNumber.toString())

        const footer = document.createElement('div')
        footer.classList.add('d-flex')
        footer.classList.add('justify-content-between')
        footer.classList.add('px-1')

        const infoText = document.createElement('span')
        infoText.innerText = `${this.pageTextValue} ${pageNumber}`

        const rotateLeft = document.createElement('button')
        rotateLeft.setAttribute('type', 'button')
        const rotateLeftClasses = ['btn', 'btn-link', 'p-0']
        rotateLeftClasses.forEach(cls => rotateLeft.classList.add(cls))
        const rotateLeftIcon = document.createElement('i')
        rotateLeftIcon.classList.add('fas')
        rotateLeftIcon.classList.add('fa-rotate-left')
        rotateLeft.appendChild(rotateLeftIcon)

        const rotateRight = document.createElement('button')
        rotateRight.setAttribute('type', 'button')
        const rotateRightClasses = ['btn', 'btn-link', 'p-0']
        rotateRightClasses.forEach(cls => rotateRight.classList.add(cls))
        const rotateRightIcon = document.createElement('i')
        rotateRightIcon.classList.add('fas')
        rotateRightIcon.classList.add('fa-rotate-right')
        rotateRight.appendChild(rotateRightIcon)

        const input = document.createElement('input')
        input.type = 'hidden'
        input.value = '0'
        input.name = `manipulation_step[manipulation_options][rotate_pages][${this.stepFileInputIdValue}][pages][${pageNumber}]`
        wrapper.appendChild(input)

        rotateLeft.addEventListener('click', () => (this._rotate(pageNumber, wrapper, input, -90)))
        rotateRight.addEventListener('click', () => (this._rotate(pageNumber, wrapper, input, 90)))

        wrapper.append(footer)
        footer.appendChild(rotateLeft)
        footer.appendChild(infoText)
        footer.appendChild(rotateRight)

        return wrapper
    }

    _rotate(pageNumber, wrapper, input, degrees) {
        if (!this.pages[pageNumber]) this.pages[pageNumber] = 0

        this.pages[pageNumber] = (this.pages[pageNumber] + degrees + 360) % 360
        const canvas = wrapper.querySelector('canvas') // urg...
        canvas.style.transform = `rotate(${this.pages[pageNumber]}deg)`

        if (this.pages[pageNumber] % 180 !== 0) {
            canvas.style.height = `${canvas.clientWidth}px`
        } else {
            canvas.style.height = null
        }

        input.value = this.pages[pageNumber].toString()
    }
}

