import de_DE from './locale/de_DE'
import en_US from './locale/en_US'

export const buildIconButton = ({icon, text}, clickCallback) => {
    const button = document.createElement('button')
    button.classList.add('pdfjs--toolbar--button')
    const iconEl = document.createElement('i')
    const iconClasses = ['fa-solid', 'fa-fw', `fa-${icon}`]
    iconClasses.forEach(c => (iconEl.classList.add(c)))
    button.append(iconEl)
    if (text) {
        const span = document.createElement('span')
        span.innerText = text
        button.append(span)
    }
    button.addEventListener('click', clickCallback)

    return button
}

const buildPageSelect = (pages, locale, changeCallback) => {
    const select = document.createElement('select')
    select.classList.add('pdfjs--toolbar--select')
    for (let i = 1; i <= pages; i++) {
        const option = document.createElement('option')
        option.value = i.toString()
        option.innerText = `${locale.strings.page} ${i.toString()}`
        select.appendChild(option)
    }
    select.addEventListener('change', () => changeCallback(parseInt(select.value)))
    return select
}

const buildToolbarSpacer = () => {
    const div = document.createElement('div')
    div.classList.add('d-inline-block')
    div.classList.add('mx-1')
    return div
}

const buildAddSignatureButton = (locale) => {
    const button = document.createElement('button')
    button.classList.add('pdfjs--toolbar--button')
    button.classList.add('bg-success')
    const iconEl = document.createElement('i')
    const iconClasses = ['fa-solid', 'fa-fw', 'fa-plus']
    iconClasses.forEach(c => (iconEl.classList.add(c)))
    button.append(iconEl)
    const span = document.createElement('span')
    span.innerText = locale.strings.addSignature
    button.append(span)

    button.setAttribute('data-bs-toggle', 'modal')
    button.setAttribute('data-bs-target', '#signPdfModal')

    return button
}

const buildAddTextButton = (locale) => {
    const button = document.createElement('button')
    button.classList.add('pdfjs--toolbar--button')
    button.classList.add('bg-default')
    const iconEl = document.createElement('i')
    const iconClasses = ['fa-solid', 'fa-fw', 'fa-font']
    iconClasses.forEach(c => (iconEl.classList.add(c)))
    button.append(iconEl)
    const span = document.createElement('span')
    span.innerText = locale.strings.addText
    button.append(span)

    return button
}

export const buildPdfjsToolbar = (controller, disableSignatures) => {
    const locale = document.documentElement.lang === 'en' ? en_US : de_DE

    const prevPageButton = buildIconButton({icon: 'arrow-left'}, () => (controller._setActivePage(controller.activePage - 1)))
    const nextPageButton = buildIconButton({icon: 'arrow-right'}, () => (controller._setActivePage(controller.activePage + 1)))
    const firstPageButton = buildIconButton({icon: 'backward-fast'}, () => (controller._setActivePage(1)))
    const lastPageButton = buildIconButton({icon: 'forward-fast'}, () => (controller._setActivePage(controller.totalPages)))
    const zoomInButton = buildIconButton({icon: 'magnifying-glass-plus'}, controller.increaseScale.bind(controller))
    const zoomOutButton = buildIconButton({icon: 'magnifying-glass-minus'}, controller.decreaseScale.bind(controller))
    const addTextButton = buildAddTextButton(locale)
    addTextButton.addEventListener('click', () => controller.addTextToActivePage())
    controller.pageSelect = buildPageSelect(controller.totalPages, locale, controller._setActivePage.bind(controller))

    controller.toolbarTarget.appendChild(firstPageButton)
    controller.toolbarTarget.appendChild(prevPageButton)
    controller.toolbarTarget.appendChild(controller.pageSelect)
    controller.toolbarTarget.appendChild(nextPageButton)
    controller.toolbarTarget.appendChild(lastPageButton)
    controller.toolbarTarget.appendChild(buildToolbarSpacer())
    controller.toolbarTarget.appendChild(zoomOutButton)
    controller.toolbarTarget.appendChild(zoomInButton)
    controller.toolbarTarget.appendChild(buildToolbarSpacer())
    if (!disableSignatures) controller.toolbarTarget.appendChild(buildAddSignatureButton(locale))
    controller.toolbarTarget.appendChild(addTextButton)
}
