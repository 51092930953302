import {Controller} from '@hotwired/stimulus'
import {buildIconButton} from '../components/pdfjs/toolbar_helper'

export default class extends Controller {
    static targets = ['canvas', 'button', 'undo', 'redo', 'toolbar', 'svg']
    static values = {
        uploadUrl: String,
        lineWidth: Number,
        undoText: {type: String, default: 'Undo'},
        repeatText: {type: String, default: 'Repeat'},
        emptyText: {type: String, default: 'Empty'},
    }

    connect() {
        this.ctx = this.canvasTarget.getContext('2d')
        this._setupCtx()
        this._setupToolbar()
        this._setupDrawHistory()
        this._setupDraw()
        this._setupSaveListener()
    }

    _setupCtx() {
        this.ctx.strokeStyle = this.stokeStyle || '#1a4692'
        this.ctx.lineWidth = this.lineWidthValue || 6
        this.ctx.lineJoin = 'round'
        this.ctx.lineCap = 'round'
    }

    _setupToolbar() {
        const undoButton = buildIconButton({
            icon: 'rotate-left', text: this.undoTextValue
        }, this._drawUndo.bind(this))
        const redoButton = buildIconButton({
            icon: 'rotate-right', text: this.repeatTextValue
        }, this._drawRedo.bind(this))
        const clearButton = buildIconButton({
            icon: 'trash', text: this.emptyTextValue
        }, this._clear.bind(this))

        this.toolbarTarget.appendChild(undoButton)
        this.toolbarTarget.appendChild(redoButton)
        this.toolbarTarget.appendChild(clearButton)
    }

    _setupDrawHistory() {
        this.drawHistory = {currentDrawStep: [], doneSteps: [], undoneSteps: []}
    }

    _setupDraw() {
        let drawing = false
        let pos = {x: 0, y: 0}
        let scaleX = 1
        let scaleY = 1

        const startDrawing = e => {
            drawing = true
            scaleX = this.canvasTarget.width / this.canvasTarget.offsetWidth
            scaleY = this.canvasTarget.height / this.canvasTarget.offsetHeight
            pos = eventOffset(e)
        }

        const draw = e => {
            if (!drawing) return
            if (e.preventDefault) e.preventDefault()

            const to = eventOffset(e)
            drawLine({ctx: this.ctx, from: pos, to, scaleX, scaleY})
            this.drawHistory.currentDrawStep.push({from: pos, to, scaleX, scaleY})
            pos = to
        }

        const stopDraw = () => {
            if (!drawing) return

            draw({offsetX: pos.x, offsetY: pos.y})
            this.drawHistory.doneSteps.push(this.drawHistory.currentDrawStep)
            this.drawHistory.currentDrawStep = []
            drawing = false
        }

        this.canvasTarget.addEventListener('mousedown', startDrawing)
        this.canvasTarget.addEventListener('touchstart', startDrawing)
        this.canvasTarget.addEventListener('mousemove', draw)
        this.canvasTarget.addEventListener('touchmove', draw)

        this.canvasTarget.addEventListener('mouseleave', stopDraw)
        window.addEventListener('mouseup', stopDraw)
        window.addEventListener('touchend', stopDraw)
        window.addEventListener('touchcancel', stopDraw)
    }

    _drawUndo() {
        if (this.drawHistory.doneSteps.length === 0) return

        this.ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
        this.drawHistory.undoneSteps.push(this.drawHistory.doneSteps.pop())
        this.drawHistory.doneSteps.forEach(draw => {
            draw.forEach(({from, to, scaleX, scaleY}) => {
                drawLine({ctx: this.ctx, from, to, scaleX, scaleY})
            })
        })
    }

    _drawRedo() {
        if (this.drawHistory.undoneSteps.length === 0) return

        const draw = this.drawHistory.undoneSteps.pop()
        draw.forEach(({from, to, scaleX, scaleY}) => {
            drawLine({ctx: this.ctx, from, to, scaleX, scaleY})
        })
        this.drawHistory.doneSteps.push(draw)
    }

    _setupSaveListener() {
        this.buttonTarget.addEventListener('click', () => {
            this.canvasTarget.toBlob(blob => {
                let file = new File([blob], 'signature.png', {type: 'image/png'})

                const formData = new FormData()
                formData.append('file', file)

                fetch(this.uploadUrlValue, {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                        'Accept': 'text/vnd.turbo-stream.html'
                    },
                    body: formData
                }).then(r => (r.text()))
                    .then(body => {
                        Turbo.renderStreamMessage(body)
                        this._clear()
                        this.dispatch('drawDone')
                    })
            }, 'image/png')
        })
    }

    _clear() {
        this.ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
        this._setupDrawHistory()
    }
}

function eventOffset(event) {
    if (event.offsetX && event.offsetY) {
        return {x: event.offsetX, y: event.offsetY}
    } else { // for touch events
        const rect = event.target.getBoundingClientRect()
        const x = event.touches[0].clientX - rect.left
        const y = event.touches[0].clientY - rect.top

        return {x, y}
    }
}

function drawLine({ctx, from, to, scaleX, scaleY}) {
    ctx.beginPath()
    ctx.moveTo(from.x * scaleX, from.y * scaleY)
    ctx.lineTo(to.x * scaleX, to.y * scaleY)
    ctx.closePath()
    ctx.stroke()
}
