export const makeDraggable = element => {
    let dragging = false
    let dragPos = {x: 0, y: 0}

    element.addEventListener('mousedown', e => {
        dragPos = {x: e.clientX, y: e.clientY}
        dragging = true

        window.addEventListener('mousemove', draggableMouseMove)
        window.addEventListener('mouseup', draggableMouseUp)
    })

    const draggableMouseMove = e => {
        if (!dragging) return

        calculateOffsetLeft(element, e.clientX - dragPos.x, newOffsetLeft => {
            element.style.left = `${newOffsetLeft}px`
            dragPos.x = e.clientX
        })

        const moveYBy = e.clientY - dragPos.y
        let newOffsetTop = moveYBy + element.offsetTop
        if ((newOffsetTop + element.clientHeight) > (element.parentElement.clientHeight)) {
            // move to next page
            if (moveYBy > 60) {
                const nextParent = element.parentElement?.parentElement?.nextElementSibling?.childNodes[0]

                if (nextParent) {
                    nextParent.appendChild(element)
                    element.style.top = '0px'
                    dragPos.y = dragPos.y + moveYBy
                }
            } else {
                element.style.top = `${element.parentElement.clientHeight - element.clientHeight}px`
            }
        } else if (newOffsetTop < 0) {
            if (moveYBy < -60) {
                const prevParent = element.parentElement?.parentElement?.previousElementSibling?.childNodes[0]

                if (prevParent) {
                    prevParent.appendChild(element)
                    const top = prevParent.clientHeight - element.clientHeight - moveYBy
                    element.style.top = `${top}px`
                    dragPos.y = element.parentElement.clientHeight
                }
            } else {
                element.style.top = '0px'
            }
        } else {
            element.style.top = `${newOffsetTop}px`
            dragPos.y = e.clientY
        }
    }

    const draggableMouseUp = () => {
        dragging = false

        window.removeEventListener('mousemove', draggableMouseMove)
        window.removeEventListener('mouseup', draggableMouseUp)
    }
}

const calculateOffsetLeft = (element, moveXBy, callback) => {
    if (moveXBy === 0) return

    let newOffsetLeft = moveXBy + element.offsetLeft
    if ((newOffsetLeft + element.clientWidth) > element.parentElement.clientWidth) {
        newOffsetLeft = element.parentElement.clientWidth - element.clientWidth
    } else if (newOffsetLeft < 0) {
        newOffsetLeft = 0
    }

    callback(newOffsetLeft)
}
