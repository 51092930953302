import {makeDraggable} from './draggable'
import {makeResizable} from './resizable'

const FONT_SIZE = 18

export class DraggableText {
    constructor(scale = 1) {
        this.scale = scale
        this.wrapper = document.createElement('div')
        this.wrapper.classList.add('draggable')

        const textWrapper = document.createElement('div')
        textWrapper.classList.add('draggable--content')
        textWrapper.classList.add('w-100')
        textWrapper.classList.add('h-100')
        textWrapper.classList.add('text-start')

        this.trix = document.createElement('trix-editor')
        this.trix.setAttribute('toolbar', 'trix-toolbar')
        this.trix.addEventListener('focus', () => {
            document.getElementById('trix-toolbar').style.visibility = 'visible'
            this.wrapper.classList.add('draggable__active')
        })
        this.trix.addEventListener('blur', () => {
            document.getElementById('trix-toolbar').style.visibility = 'hidden'
            this.wrapper.classList.remove('draggable__active')
        })
        this.trix.addEventListener('trix-initialize', () => (this.trix.focus()))
        this.trix.style.fontSize = `${FONT_SIZE * scale}px`
        textWrapper.appendChild(this.trix)

        makeDraggable(this.wrapper)
        makeResizable(this.wrapper)
        this.removeBtn = addRemovable(this.wrapper)

        this.wrapper.appendChild(textWrapper)

        this.id = Math.random().toString().substring(2)
    }

    formData() {
        return {
            text: this.trix.value,
            // we randomly add 4px to fix some scaling issues, might happen because of border etc
            top: (this.wrapper.offsetTop / this.scale) + 7,
            width: this.wrapper.clientWidth / this.scale,
            height: this.wrapper.clientHeight / this.scale,
            left: (this.wrapper.offsetLeft / this.scale) + 7,
            pdfWidth: this.wrapper.parentElement.getAttribute('data-pdf-width'),
            pdfHeight: this.wrapper.parentElement.getAttribute('data-pdf-height'),
            page: this.wrapper.parentElement.getAttribute('data-page')
        }
    }

    rescale(prevScale, newScale) {
        this.wrapper.style.width = `${(this.wrapper.clientWidth / prevScale) * newScale}px`
        this.wrapper.style.height = `${(this.wrapper.clientHeight / prevScale) * newScale}px`
        this.wrapper.style.top = `${(this.wrapper.offsetTop / prevScale) * newScale}px`
        this.wrapper.style.left = `${(this.wrapper.offsetLeft / prevScale) * newScale}px`
        this.trix.style.fontSize = `${FONT_SIZE * newScale}px`
        this.scale = newScale
    }
}

const addRemovable = element => {
    const removable = document.createElement('button')
    removable.classList.add('position-absolute')
    removable.classList.add('text-center')
    removable.classList.add('btn')
    removable.classList.add('btn-secondary')
    removable.classList.add('btn-sm')
    removable.classList.add('p-05')
    removable.style.right = '-40px'
    removable.innerHTML = '<i class="fas fa-trash"></i>'
    element.appendChild(removable)
    return removable
}