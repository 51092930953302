import {Controller} from '@hotwired/stimulus'
import Bugsnag from '@bugsnag/js'

export default class extends Controller {
    initialize() {
        if (!this.loadingAllowed()) return

        Bugsnag.start({
            apiKey: '513a1e34b5326230a81b9951633a0241',
            appVersion: document.body.dataset.appVersion,
            collectUserIp: false
        })
    }

    loadingAllowed() {
        return document.body.dataset.cookieConsent === 'true' && (document.documentElement.dataset.env === 'production')
    }
}
