import {Controller} from '@hotwired/stimulus'
import PagePreview from '../components/pdfjs/page_preview'

export default class extends Controller {
    static targets = ['preview', 'summary']
    static values = {
        previewUrl: String,
        stepFileInputId: Number,
        pageText: {type: String, default: 'Page'},
        pagesSelectedText: {type: String, default: 'pages selected'}
    }

    async connect() {
        const options = {
            pdfUrl: this.previewUrlValue,
            previewTarget: this.previewTarget,
            buildPage: this._buildPage.bind(this)
        }
        const preview = new PagePreview(options)
        preview.setupPdfJs()
        preview.setupObserver()
        await preview.loadPdf()
        await preview.buildDom()
        this.updateSummary()
    }

    _buildPage(pageNumber) {
        const wrapper = document.createElement('div')
        wrapper.classList.add('cut-pdf-page')
        wrapper.setAttribute('data-page', pageNumber.toString())

        const input = document.createElement('input')
        input.classList.add('cut_pdf__input')
        input.type = 'checkbox'
        input.name = `manipulation_step[manipulation_options][cut][${this.stepFileInputIdValue}][pages][]`
        input.id = `manipulation_step_manipulation_options_cut_${this.stepFileInputIdValue}_pages_${pageNumber}`
        input.value = pageNumber
        input.autocomplete = 'off'
        input.checked = true
        input.setAttribute('data-action', 'change->cut-pdf#updateSummary')

        const label = document.createElement('label')
        label.classList.add('cut_pdf__label')
        label.setAttribute('for', input.id)

        wrapper.appendChild(label)

        const info = document.createElement('div')
        info.classList.add('cut_pdf__text')

        const infoText = document.createElement('span')
        infoText.innerText = `${this.pageTextValue} ${pageNumber}`

        wrapper.appendChild(info)
        info.appendChild(input)
        info.appendChild(infoText)

        return wrapper
    }

    updateSummary() {
        const count = this.element.querySelectorAll('input[type="checkbox"]:checked').length
        this.summaryTarget.innerText = `${count} / ${this.totalPages} ${this.pagesSelectedTextValue}`
    }
}
