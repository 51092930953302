import {Controller} from '@hotwired/stimulus'

import consumer from '../channels/consumer'

export default class extends Controller {
    static targets = ['statusText']
    static values = {
        fileConversionUuid: String,
        manipulationStepUuid: String
    }

    connect() {
        consumer.subscriptions.create({
            channel: 'MobileSignChannel',
            file_conversion_uuid: this.fileConversionUuidValue,
            manipulation_step_uuid: this.manipulationStepUuidValue
        }, {
            connected: this._channelConnected.bind(this),
            disconnected: this._channelDisconnected.bind(this),
            received: this._channelReceived.bind(this)
        })
    }

    disconnect() {
        consumer.disconnect()
    }

    _channelConnected() {
        console.info('MobileSign connected')
    }

    _channelDisconnected() {
        console.info('MobileSign disconnected')
    }

    _channelReceived({details}) {
        switch (details.status) {
            case 'started':
                this.statusTextTarget.innerText = details.msg
                break
            case 'finished':
                if (details.template) Turbo.renderStreamMessage(details.template)
                break
        }
        this.dispatch(`mobileSign:${details.status}`)
    }
}
