import {makeDraggable} from './draggable'
import {makeResizable} from './resizable'

export class DraggableSignature {
    constructor({width, height, download_url: downloadUrl, uuid}, scale = 1) {
        this.scale = scale
        this.wrapper = document.createElement('div')
        this.wrapper.classList.add('draggable')

        const signature = document.createElement('div')
        signature.classList.add('draggable--content')
        signature.classList.add('w-100')
        signature.classList.add('h-100')
        signature.style.background = `url(${downloadUrl}) no-repeat`
        signature.style.backgroundSize = '100% 100%'

        makeDraggable(this.wrapper)
        makeResizable(this.wrapper)
        this.removeBtn = addRemovable(this.wrapper)

        const scaled = scaleDownDimensions({width, height})
        this.wrapper.style.width = `${(scaled.width || 200) * this.scale}px`
        this.wrapper.style.height = `${(scaled.height || 100) * this.scale}px`
        this.wrapper.appendChild(signature)

        this.fileBlobUuid = uuid
        this.id = Math.random().toString().substring(2)
    }

    formData() {
        return {
            file_blob_uuid: this.fileBlobUuid,
            top: this.wrapper.offsetTop / this.scale,
            left: this.wrapper.offsetLeft / this.scale,
            width: this.wrapper.clientWidth / this.scale,
            height: this.wrapper.clientHeight / this.scale,
            pdfWidth: this.wrapper.parentElement.getAttribute('data-pdf-width'),
            pdfHeight: this.wrapper.parentElement.getAttribute('data-pdf-height'),
            page: this.wrapper.parentElement.getAttribute('data-page')
        }
    }

    rescale(prevScale, newScale) {
        this.wrapper.style.width = `${(this.wrapper.clientWidth / prevScale) * newScale}px`
        this.wrapper.style.height = `${(this.wrapper.clientHeight / prevScale) * newScale}px`
        this.wrapper.style.top = `${(this.wrapper.offsetTop / prevScale) * newScale}px`
        this.wrapper.style.left = `${(this.wrapper.offsetLeft / prevScale) * newScale}px`
        this.scale = newScale
    }
}

const MAX_WIDTH = 300
const MAX_HEIGHT = 200
const scaleDownDimensions = ({width, height}) => {
    if (width <= MAX_WIDTH && height <= MAX_HEIGHT) return {width, height}

    if (width > MAX_WIDTH) {
        const factor = MAX_WIDTH / width
        return {width: MAX_WIDTH, height: height * factor}
    }

    if (height > MAX_HEIGHT) {
        const factor = MAX_HEIGHT / height
        return {width: width * factor, height: MAX_HEIGHT}
    }
}

const addRemovable = element => {
    const removable = document.createElement('button')
    removable.classList.add('position-absolute')
    removable.classList.add('text-center')
    removable.classList.add('btn')
    removable.classList.add('btn-secondary')
    removable.classList.add('btn-sm')
    removable.classList.add('p-05')
    removable.style.right = '-40px'
    removable.innerHTML = '<i class="fas fa-trash"></i>'
    element.appendChild(removable)
    return removable
}