import {Controller} from '@hotwired/stimulus'

import consumer from '../channels/consumer'

export default class extends Controller {
    static targets = ['statusText']
    static values = {
        fileConversionUuid: String,
        redirectUrl: String
    }

    connect() {
        consumer.subscriptions.create(
            {
                channel: 'FileConversionChannel',
                uuid: this.fileConversionUuidValue
            },
            {
                connected: this._channelConnected.bind(this),
                disconnected: this._channelDisconnected.bind(this),
                received: this._channelReceived.bind(this)
            })

        this.interval = setInterval(() => (window.location.reload()), 3000)
    }

    disconnect() {
        consumer.disconnect()
        clearInterval(this.interval)
    }

    _channelConnected() {
        console.info('WS connected')
    }

    _channelDisconnected() {
        console.info('WS disconnected')
    }

    _channelReceived({type, details}) {
        console.log(type, details)

        this.statusTextTarget.innerHTML = details.msg
        if (details.status === 'finished') {
            setTimeout(() => (window.location.replace(this.redirectUrlValue)), 1000)
        }
    }
}
