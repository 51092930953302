import * as pdfjs from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

export default class PagePreview {
    constructor({pdfUrl, previewTarget, buildPage}) {
        this.pdfUrl = pdfUrl
        this.previewTarget = previewTarget
        this.buildPage = buildPage
    }

    setupPdfJs() {
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
    }

    setupObserver() {
        this.pageObserver = new IntersectionObserver(changes => {
            changes.forEach(change => {
                if (change.isIntersecting) {
                    this._render(change.target)
                }
            })
        }, {threshold: 0.1})
    }

    async loadPdf() {
        const loader = pdfjs.getDocument(this.pdfUrl)
        this.pdf = await loader.promise
        this.totalPages = this.pdf.numPages
    }

    async buildDom() {
        for (let i = 1; i <= this.totalPages; i++) {
            const wrapper = this.buildPage(i)
            this.previewTarget.appendChild(wrapper)

            this.pageObserver.observe(wrapper)
        }
    }

    async _render(wrapper) {
        const pageNumber = wrapper.getAttribute('data-page')
        const page = await this.pdf.getPage(parseInt(pageNumber))
        const scale = .5
        const viewport = page.getViewport({scale: scale})

        const canvas = document.createElement('canvas')
        canvas.classList.add('document-preview--img')
        canvas.width = Math.floor(viewport.width)
        canvas.height = Math.floor(viewport.height)
        wrapper.prepend(canvas)

        const canvasContext = canvas.getContext('2d')
        const renderContext = {canvasContext, viewport}

        page.render(renderContext)
        this.pageObserver.unobserve(wrapper)
    }
}
