import {Controller} from '@hotwired/stimulus'
import PagePreview from '../components/pdfjs/page_preview'

export default class extends Controller {
    static targets = ['preview']
    static values = {
        previewUrl: String,
        stepFileInputId: Number,
        pageText: {type: String, default: 'Page'}
    }

    async connect() {
        const options = {
            pdfUrl: this.previewUrlValue,
            previewTarget: this.previewTarget,
            buildPage: this._buildPage.bind(this)
        }
        const preview = new PagePreview(options)
        preview.setupPdfJs()
        preview.setupObserver()
        await preview.loadPdf()
        await preview.buildDom()
    }

    _buildPage(pageNumber) {
        const wrapper = document.createElement('div')
        wrapper.classList.add('cut-pdf-page')
        wrapper.setAttribute('data-page', pageNumber.toString())

        const info = document.createElement('div')
        info.classList.add('cut_pdf__text')

        const infoText = document.createElement('span')
        infoText.innerText = `${this.pageTextValue} ${pageNumber}`

        wrapper.appendChild(info)
        info.appendChild(infoText)

        return wrapper
    }
}
