// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'
import './controllers/application/index'

import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/tab'
import './components/font_awesome'
import './components/trix'

document.addEventListener('turbo:load', (_event) => {
    // Placeholder
})
