import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {
    faTachometerAlt,
    faHouse,
    faArrowRightLong,
    faStar,
    faSignOutAlt,
    faFilePdf,
    faPlus,
    faArrowLeft,
    faArrowRight,
    faBackwardFast,
    faForwardFast,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faTrash,
    faPencil,
    faMobileScreen,
    faImage,
    faRotateLeft,
    faRotateRight,
    faGlobe,
    faFont
} from '@fortawesome/free-solid-svg-icons'
import {faStar as farStar} from '@fortawesome/free-regular-svg-icons'
import {faFacebook, faTwitter, faInstagram, faWhatsapp, faLinkedin} from '@fortawesome/free-brands-svg-icons'

document.addEventListener('DOMContentLoaded', function (_event) {
    library.add(faTachometerAlt, faHouse, faArrowRightLong, faStar, farStar, faFacebook, faTwitter, faInstagram,
        faWhatsapp, faLinkedin, faSignOutAlt, faFilePdf, faPlus, faArrowLeft, faArrowRight, faBackwardFast,
        faForwardFast, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faTrash, faPencil, faMobileScreen, faImage,
        faRotateLeft, faRotateRight, faGlobe, faFont)
    dom.watch()
})
